import { CopyOutlined, WarningOutlined } from "@ant-design/icons";
import useDebounceWindowResize from "@hook/useDebounceWindowResize";
import DrawerHeader from "@layouts/trade/header/DrawerHeader";
import { Grid } from "@mui/material";
import { whiteColor } from "@src/styles/modules/colors";
import { IviIcon, LogoIcon, ThreeDot } from "@svg";
import { copyToClipBoard } from "@utils";
import { canHover, formatPrice, getTooltipDisableProps } from "@utils/index";
import { Popover, Select, Tooltip } from "antd";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { formatUnits } from "viem";
import {
  erc20ABI,
  useAccount,
  useContractRead,
  useNetwork,
  useSwitchNetwork,
  useToken,
} from "wagmi";
import { AccountPanelBody, AccountPanelHead } from "./AccountPanel";
import { MenuHorizontalComponents } from "./menuHozStyled";
import { HeaderWrapper, LogoHeaderStyled } from "./styled";
import {
  generateDefaultOpenKeys,
  generateMenu,
  getTradeHeaderItems,
} from "./utils";

export const MenuModeEnum = {
  Inline: "inline",
  Horizontal: "horizontal",
};

const Header = () => {
  const { currentContractProperties } = useSelector((state) => state.contracts);
  const { pathname, search } = useLocation();

  const menus = filter(
    getTradeHeaderItems({
      history: useHistory(),
    }),
    (item) => !item.inSettingOnly
  );

  const defaultSelectedKeys = generateDefaultOpenKeys(menus, pathname);

  return (
    <HeaderWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} lg={1.5}>
          <Logo />
        </Grid>
        <Grid item xs={12} lg={5.5} xxxl={6.5} textAlign="center">
          <MenuHorizontalComponents.NavMenu
            popupClassName="ant-menu-sub-bg-dark"
            defaultSelectedKeys={defaultSelectedKeys}
            mode={MenuModeEnum.Horizontal}
          >
            {generateMenu({
              data: menus,
              variant: true,
              path: `${pathname}${search}`,
              ParentComponent: MenuHorizontalComponents,
              subMenuTextProps: {
                color: whiteColor,
              },
              subMenuProps: {
                color: whiteColor,
                hoverColor: whiteColor,
                popupClassName: "ant-menu-sub-bg-dark",
              },
              menuItemProps: {
                color: whiteColor,
                hoverColor: whiteColor,
              },
            })}
          </MenuHorizontalComponents.NavMenu>
        </Grid>
        <Grid item lg={5} xxxl={4}>
          <div className="info-account">
            <NetworkInfos />
            <WalletTokenConnectedInfos
              currentContractProperties={currentContractProperties}
            />
            <AccountSettings
              currentContractProperties={currentContractProperties}
            />
          </div>
        </Grid>
      </Grid>
      <DrawerHeader />
    </HeaderWrapper>
  );
};

export default Header;

function Logo() {
  return (
    <Link to="/tutorial" style={{ color: whiteColor }}>
      <LogoHeaderStyled className="logo-header">
        <LogoIcon width={120} />
      </LogoHeaderStyled>
    </Link>
  );
}

function NetworkInfos() {
  const { chain, chains } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const [chainValue, setChainValue] = useState();
  useEffect(() => {
    if (chain) {
      console.log(chains, "chains123");
      const matchChain = chains.find((item) => chain.id === item.id);
      if (matchChain) {
        setChainValue(chain.id);
      } else {
        switchNetwork(chains[0].id);
        setChainValue("Unsupported");
      }
    }
  }, [chain?.id]);

  return (
    <div className="network">
      {chains.find((item) => chain.id === item.id) ? (
        <div className={`network-select-icon dot`}></div>
      ) : (
        <div className={`network-select-icon warning-icon`}>
          <WarningOutlined style={{ fontSize: "75%", color: "#ee8f13" }} />
        </div>
      )}

      <div className="network-select">
        <Select
          style={{ width: "140px" }}
          onChange={(value) => {
            switchNetwork(value);
          }}
          value={chainValue}
          options={chains.map((item) => ({
            ...item,
            value: item?.id,
            label: item?.nativeCurrency?.symbol.toUpperCase(),
          }))}
        />
      </div>
    </div>
  );
}

function WalletTokenConnectedInfos({ currentContractProperties }) {
  const { address } = useAccount();

  const { data } = useToken({
    address: currentContractProperties?.address,
    enabled: currentContractProperties?.address,
  });
  const screenWidth = useDebounceWindowResize()?.width;

  const { data: balanceOfStableToken } = useContractRead({
    address: currentContractProperties?.address,
    abi: erc20ABI,
    functionName: "balanceOf",
    args: [address],
    enabled: address && currentContractProperties?.address,
    watch: true,
  });

  const tokenBalance = balanceOfStableToken
    ? Number(formatUnits(balanceOfStableToken, 18))?.toFixed(2)
    : 0;

  const tooltipDisableProps = getTooltipDisableProps(!canHover());

  return (
    <div className="token-address-text">
      <div className="address">
        <Tooltip title="Copy" {...tooltipDisableProps}>
          <CopyOutlined
            onClick={() => {
              copyToClipBoard(address);
            }}
          />
        </Tooltip>
        <Tooltip title={address}>
          {" "}
          {address?.substring(0, 7)}...
          {address?.substring(address?.length - 3, address?.length)}{" "}
        </Tooltip>
      </div>

      <div className="balance">
        {screenWidth > 576 && <span className="space">-</span>}
        <span className="IVI-icon">
          <IviIcon
            width={screenWidth > 576 ? 24 : 12}
            height={screenWidth > 576 ? 24 : 12}
          />
        </span>
        <span>
          {`${balanceOfStableToken ? `${formatPrice(tokenBalance)}` : 0} `}{" "}
          {data?.symbol}{" "}
        </span>
      </div>
    </div>
  );
}

function AccountSettings({ currentContractProperties }) {
  const { address } = useAccount();
  const history = useHistory();

  return (
    <div className="account">
      <div className="wrapper-account">
        <Popover
          destroyTooltipOnHide
          placement="bottomRight"
          trigger="click"
          title={() => (
            <AccountPanelHead
              currentContractProperties={currentContractProperties}
              address={address}
            />
          )}
          content={() => <AccountPanelBody history={history} />}
          overlayClassName="account-panel"
        >
          <ThreeDot />
        </Popover>
      </div>
    </div>
  );
}
